import { template as template_99aa894f1c2d4364a72c7082e68a5b40 } from "@ember/template-compiler";
const FKControlMenuContainer = template_99aa894f1c2d4364a72c7082e68a5b40(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
