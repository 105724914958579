import { template as template_3a5acd3ec5b649bc8ee9e2b1c4cde773 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_3a5acd3ec5b649bc8ee9e2b1c4cde773(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
