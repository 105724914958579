import { template as template_d7d49e6f8f8b4077a7cc590845dda788 } from "@ember/template-compiler";
const WelcomeHeader = template_d7d49e6f8f8b4077a7cc590845dda788(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
